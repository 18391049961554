<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Cierres Operativos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Cierres Operativos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div :class="form.id ? 'col-md-9' : 'col-md-12'">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="row">
                      <!-- Fecha Solicitud -->
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Fecha Solicitud</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.fecha_solicitud"
                            :class="
                              $v.form.fecha_solicitud.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="accion == 'Editar'"
                          />
                        </div>
                      </div>
                      <!-- Sitio -->
                      <div class="col-md-5">
                        <div class="form-group">
                          <label>Sitio </label>
                          <v-select
                            class="form-control form-control-sm p-0"
                            label="nombre"
                            placeholder="Sitio"
                            v-model="sitio"
                            :options="listasForms.sitios"
                            @input="selectSitio()"
                            :class="
                              $v.form.sitio_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="accion == 'Editar'"
                          >
                          </v-select>
                        </div>
                      </div>
                      <!-- Estado -->
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Estado </label>
                          <select
                            v-model="form.estado"
                            class="form-control form-control-sm"
                            :class="
                              $v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="!form.cierre_campo_user_id"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- Cierre Operativo -->
                      <div class="col-md-2 text-center" v-if="form.id">
                        <label for="check_cierre">Cierre Operativo</label>
                        <input
                          v-model="form.check_cierre"
                          id="check_cierre"
                          type="checkbox"
                          class="form-control form-control-sm"
                          @change="cierreOperativo()"
                          :disabled="
                            (guiasCargues.some(
                              (cargue) => cargue.confirmacion_user_id == null
                            ) &&
                              guiasDescargues.some(
                                (descargue) =>
                                  descargue.confirmacion_user_id == null
                              )) ||
                              estado == 2
                          "
                        />
                        <span
                          class="badge bg-warning"
                          v-if="
                            guiasCargues.some(
                              (cargue) => cargue.confirmacion_user_id == null
                            ) &&
                              guiasDescargues.some(
                                (descargue) =>
                                  descargue.confirmacion_user_id == null
                              )
                          "
                          >Sin Verificar Guías</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row justify-content-center">
                      <button
                        type="button"
                        class="btn bg-gradient-primary shadow"
                        @click="save()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.operativoCierre.save'
                          ) && !$v.form.$invalid
                        "
                        :disabled="estado == 2"
                      >
                        <i class="fas fa-paper-plane"></i>
                        Guardar
                      </button>
                      <router-link to="/Hidrocarburos/CierresOperativos">
                        <button
                          type="button"
                          class="btn bg-gradient-secondary shadow ml-2"
                        >
                          <i class="fas fa-reply"></i>
                          Volver
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!-- Card Totales-->
                <div class="row justify-content-center" v-if="form.id">
                  <!-- Card BSW-->
                  <!-- <div class="col-lg-3 col-6">
                    <div class="small-box alert-default-info shadow">
                      <div class="inner">
                        <h4>
                          <strong>{{ totGuiasCargue.totBsw }}</strong>
                        </h4>
                        <p>BSW</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-waterdrop"></i>
                      </div>
                      <a class="small-box-footer bg-info"
                        >Total (Cargue / Descargue)
                        <i class="fas fa-arrow-circle-right"></i
                      ></a>
                    </div>
                  </div> -->
                  <!-- Card NSV-->
                  <div class="col-lg-3 col-6">
                    <div class="small-box alert-default-primary shadow">
                      <div class="inner">
                        <h4>
                          <strong>{{ totGuiasCargue.totNsv }}</strong>
                        </h4>
                        <p>NSV</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-podium"></i>
                      </div>
                      <a class="small-box-footer bg-primary"
                        >Total (Cargue / Descargue)
                        <i class="fas fa-arrow-circle-right"></i
                      ></a>
                    </div>
                  </div>
                  <!-- Card GOV-->
                  <div class="col-lg-3 col-6">
                    <div class="small-box alert-default-success shadow">
                      <div class="inner">
                        <h4>
                          <strong>{{ totGuiasCargue.totGov }}</strong>
                        </h4>
                        <p>GOV</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-levels"></i>
                      </div>
                      <a class="small-box-footer bg-success"
                        >Total (Cargue / Descargue)
                        <i class="fas fa-arrow-circle-right"></i
                      ></a>
                    </div>
                  </div>
                  <!-- Card GSV-->
                  <div class="col-lg-3 col-6">
                    <div class="small-box alert-default-info shadow">
                      <div class="inner">
                        <h4>
                          <strong>{{ totGuiasCargue.totGsv }}</strong>
                        </h4>
                        <p>GSV</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-levels"></i>
                      </div>
                      <a class="small-box-footer bg-info"
                        >Total (Cargue / Descargue)
                        <i class="fas fa-arrow-circle-right"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Datos Usuario -->
              <div class="col-md-3" v-if="form.id">
                <div class="card card-outline card-primary col-md-12 shadow">
                  <div class="card-header pt-2 pb-2">
                    <h3 class="card-title">
                      <h6>
                        <i class="fas fa-users"></i>
                        Datos de Usuario
                      </h6>
                    </h3>
                  </div>
                  <div class="pt-2 pb-2 mt-2">
                    <div class="form-group">
                      <label for="user">Usuario</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="user"
                        v-model="form.user.name"
                        disabled
                      />
                    </div>
                    <div class="form-group">
                      <label for="correo">Correo</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="correo"
                        v-model="form.user.email"
                        disabled
                      />
                    </div>
                    <div class="form-group">
                      <label for="celular">Celular</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="celular"
                        v-model="form.user.cel"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Detalle Guías Cargue / Descargue -->
            <div v-if="form.id">
              <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="tab-Cargue"
                        data-toggle="tab"
                        href="#Cargue"
                        >Cargue</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="tab-Descargue"
                        data-toggle="tab"
                        href="#Descargue"
                        >Descargue</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Cargue">
                    <div class="card-body table-responsive p-0">
                      <div class="row">
                        <div class="col-md-12 mt-3">
                          <table
                            class="table table-bordered table-sm text-nowrap"
                          >
                            <thead class="table-dark">
                              <tr class="text-center ">
                                <th></th>
                                <th>Operación</th>
                                <th>Viaje</th>
                                <th>Vehículo</th>
                                <th>Remolque</th>
                                <th>Conductor</th>
                                <th>Empresa</th>
                                <th>N° Guía</th>
                                <th>Fecha Cargue</th>
                                <th>Fecha Expedición</th>
                                <th>Producto</th>
                                <th>Producto Líquido</th>
                                <th>Ruta</th>
                                <th>Sitio Destino</th>
                                <th>Api</th>
                                <th>Azufre</th>
                                <th>Bsw</th>
                                <th>Gov</th>
                                <th>Gsv</th>
                                <th>Nsv</th>
                                <th>Acciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(guiaCargue, index) in guiasCargues"
                                :key="index"
                                :class="{
                                  'alert-default-success':
                                    checkCargue[index] ||
                                    guiaCargue.confirmacion_user_id,
                                }"
                              >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                  <div
                                    v-if="
                                      guiaCargue.guia_type == 'App\\DetGuia'
                                    "
                                  >
                                    <span class="badge bg-navy">
                                      Ventas
                                    </span>
                                  </div>
                                  <div
                                    v-if="
                                      guiaCargue.guia_type ==
                                        'App\\DetGuiaRegalias'
                                    "
                                  >
                                    <span class="badge bg-navy">
                                      {{ guiaCargue.nOperacion }}
                                    </span>
                                  </div>
                                </td>
                                <td class="text-center">
                                  {{
                                    guiaCargue.numero_viaje
                                      ? guiaCargue.numero_viaje
                                      : guiaCargue.operacion_id
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.placa }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.remolque }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.nombre_conductor }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.transportadora }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.numero_guia }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.fecha_cargue }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.fecha_expedicion }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.producto }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.producto_liquido }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.ruta ? guiaCargue.ruta : null }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.sitio_destino }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.api }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.azufre }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.bsw }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.gov }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.gsv }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.nsv }}
                                </td>
                                <td class="text-center">
                                  <input
                                    type="checkbox"
                                    v-model="checkCargue[index]"
                                    @change="
                                      saveVerificacion(
                                        checkCargue[index],
                                        guiaCargue,
                                        index,
                                        'Cargue'
                                      )
                                    "
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.operativoCierre.verificar'
                                      )
                                    "
                                    :disabled="estado == 2 || form.check_cierre"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Guías Descargue  -->
                  <div class="tab-pane fade" id="Descargue">
                    <div class="card-body table-responsive p-0">
                      <div class="row">
                        <div class="col-md-12 mt-3">
                          <table
                            class="table table-bordered table-sm text-nowrap"
                          >
                            <thead class="table-dark">
                              <tr class="text-center ">
                                <th></th>
                                <th>Operación</th>
                                <th>Viaje</th>
                                <th>Vehículo</th>
                                <th>Remolque</th>
                                <th>Conductor</th>
                                <th>Empresa</th>
                                <th>N° Guía</th>
                                <th>Fecha Cargue</th>
                                <th>Fecha Expedición</th>
                                <th>Producto</th>
                                <th>Producto Líquido</th>
                                <th>Ruta</th>
                                <th>Sitio Destino</th>
                                <th>Api</th>
                                <th>Azufre</th>
                                <th>Bsw</th>
                                <th>Gov</th>
                                <th>Gsv</th>
                                <th>Nsv</th>
                                <th>Acciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(guiaDescargue,
                                indice) in guiasDescargues"
                                :key="indice"
                                :class="{
                                  'alert-default-primary':
                                    checkDescargue[indice] ||
                                    guiaDescargue.confirmacion_user_id,
                                }"
                              >
                                <td class="text-center">{{ indice + 1 }}</td>
                                <td class="text-center">
                                  <span class="badge bg-navy">
                                    Ventas
                                  </span>
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.numero_viaje }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.placa }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.remolque }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.nombre_conductor }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.transportadora }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.numero_guia }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.fecha_cargue }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.fecha_expedicion }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.producto }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.producto_liquido }}
                                </td>
                                <td class="text-center">
                                  {{
                                    guiaDescargue.ruta
                                      ? guiaDescargue.ruta
                                      : null
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.sitio_destino }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.api }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.azufre }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.bsw }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.gov }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.gsv }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.nsv }}
                                </td>
                                <td class="text-center">
                                  <input
                                    type="checkbox"
                                    v-model="checkDescargue[indice]"
                                    @change="
                                      saveVerificacion(
                                        checkDescargue[indice],
                                        guiaDescargue,
                                        indice,
                                        'Descargue'
                                      )
                                    "
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.operativoCierre.verificar'
                                      )
                                    "
                                    :disabled="estado == 2 || form.check_cierre"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "CierreOperativoForm",

  components: {
    Loading,
    vSelect,
  },

  data() {
    return {
      cargando: false,
      accion: null,
      metodo: null,
      estado: null,
      sitio: {},
      guiasCargues: [],
      guiasDescargues: [],
      checkCargue: [],
      checkDescargue: [],
      totGuiasCargue: {},
      form: {
        sitio_id: null,
        user_id: null,
        fecha_solicitud: null,
        fecha_cierre_campo: null,
        check_cierre: false,
        estado: null,
      },
      listasForms: {
        sitios: [],
        estados: [],
      },
    };
  },

  validations: {
    form: {
      sitio_id: {
        required,
      },
      fecha_solicitud: {
        required,
      },
      estado: {
        required,
      },
    },
  },

  methods: {
    async init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      this.form.user_id = this.$store.getters.getUser.id;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        this.form.check_cierre = this.form.fecha_cierre_campo ? true : false;
        this.form = this.$route.params.data_edit;
        this.sitio = this.form.sitio;
        await this.getDetalle();
        this.guiasCargues.forEach((guia, index) => {
          this.$set(
            this.checkCargue,
            index,
            guia.confirmacion_user_id ? true : false
          );
        });
        this.guiasDescargues.forEach((guiaD, indice) => {
          // Llenar checkDescargue en las mismas posiciones de guiasDescargues
          this.$set(
            this.checkDescargue,
            indice,
            guiaD.confirmacion_user_id ? true : false
          );
        });
        this.estado = this.$route.params.data_edit.estado;
        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
        this.estado = 1;
        this.cargando = false;
      }
      this.cargando = false;
    },

    async getDetalle() {
      this.cargando = true;
      await axios
        .get(
          "/api/hidrocarburos/cierresOperativos/showDetCierre/" + this.form.id
        )
        .then((response) => {
          this.guiasCargues = response.data.guiasCargues;
          this.guiasDescargues = response.data.guiasVDescargue;
          this.totGuiasCargue = response.data.guiasCargueTot;
          this.cargando = false;
        });
      this.cargando = false;
    },

    async getSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      await axios
        .get(url)
        .then(function(response) {
          me.listasForms.sitios = response.data;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getEstados() {
      await axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    selectSitio() {
      this.form.sitio_id = null;
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
      }
    },

    async save() {
      this.cargando = true;
      await axios({
        method: this.metodo,
        url: "/api/hidrocarburos/cierresOperativos",
        data: this.form,
      })
        .then(async (response) => {
          this.$route.params.accion = "Editar";
          this.$route.params.data_edit = response.data;
          await this.init();
          this.$swal({
            icon: "success",
            title: "El cierre operativo se guardó correctamente.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    async saveVerificacion(check, guia, index, tipo) {
      if (check) {
        await this.$swal({
          title: "Esta seguro de verificar este registro?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Verificar!",
        }).then(async (result) => {
          if (result.value) {
            this.cargando = true;
            let data = {
              det_cierre_id: guia.det_cierre_id,
              cierre_id: guia.tipo_operacion,
              accion: 1,
            };
            await axios
              .post(
                "/api/hidrocarburos/cierresOperativos/saveVerificacion",
                data
              )
              .then(async (response) => {
                this.$route.params.data_edit = response.data.cierre;
                await this.init();
                this.$swal({
                  icon: "success",
                  title: response.data.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.cargando = false;
              });
          } else {
            if (tipo == "Cargue") {
              this.$set(this.checkCargue, index, false);
            } else {
              this.$set(this.checkDescargue, index, false);
            }
          }
        });
      } else {
        await this.$swal({
          title: "Esta seguro de quitar el verificado de este registro?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Acepto!",
        }).then(async (result) => {
          if (result.value) {
            this.cargando = true;
            let data = {
              det_cierre_id: guia.det_cierre_id,
              cierre_id: guia.tipo_operacion,
              accion: 2,
            };
            await axios
              .post(
                "/api/hidrocarburos/cierresOperativos/saveVerificacion",
                data
              )
              .then(async (response) => {
                this.$route.params.data_edit = response.data.cierre;
                await this.init();
                this.$swal({
                  icon: "success",
                  title: response.data.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.cargando = false;
              });
          } else {
            await this.init();
          }
        });
      }
    },

    async cierreOperativo() {
      await this.$swal({
        title:
          "Esta seguro de cerrar esta operación de " +
          this.form.sitio.nombre +
          "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Cerrar!",
      }).then(async (result) => {
        if (result.value) {
          this.cargando = true;
          let id = this.form.id;
          await axios
            .post("/api/hidrocarburos/cierresOperativos/saveCierre", {
              id,
            })
            .then(async (response) => {
              this.$route.params.data_edit = response.data.cierre;
              await this.init();
              await this.$swal({
                icon: "success",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
        } else {
          this.form.fecha_cierre_campo = false;
        }
      });
    },
  },

  async beforeMount() {
    await this.getSitios();
    await this.getEstados();
  },

  async mounted() {
    await this.init();
  },
};
</script>
